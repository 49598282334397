<template>
<img :src="screen3" style="height: 100vh;width: 100vw;" />
    <!-- <div>
        <bg></bg>
        <page-top></page-top>
        <div class="content_view">
            <component :is="current" />
        </div>
    </div> -->
</template>

<script>
// import pageTop from '../components/top.vue';
// import bg from '../components/bg.vue'
// import home from './index.bak.vue'
import screen3 from '@/assets/screen/screen3.png'
export default {
    // components: {
    //     pageTop,
    //     bg,
    //     home
    // },
    data() {
        return {
            current: 'home',
            screen3
        };
    }
};
</script>
<style lang="scss" scoped>
html {
    overflow: hidden;

    body {
        background: transparent;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        letter-spacing: 0.07vw;

        .content_view {
            height: 90vh;
        }
    }
}
</style>
